.App {
  top: 0px;
  left: 0px;
  background: #EAE8E9 0% 0% no-repeat padding-box;
  opacity: 1;
}
@media print {
  @page {
    margin-top: 15px;
    margin-bottom: 0;
  }
  body {
    padding-top: 72px;
    padding-bottom: 72px ;
  }
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.boutonImg:hover .tooltiptext {
  visibility: visible;
}